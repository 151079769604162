let Custom;

export default Custom;

/* logoanimation
  -------------------------------------------------- */
/* initiate animation */
lottie.loadAnimation({
  container: document.getElementById('logo'),
  renderer: 'svg',
  loop: true,
  autoplay: true,
  path: 'resources/anim/logoanimation.json'
});
